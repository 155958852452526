<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      {{ heroTitle }}
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component
          :title="formCardTitle"
          icon="account-edit"
          class="tile is-child"
        >
          <form @submit.prevent="submit">
              <b-field label="Pilih Jenjang" horizontal>
              <v-select v-model="form.jenjang_id" label="name" :options="jenjang" :reduce="x => x.id"></v-select>
            </b-field>
            <hr>
            <b-field label="Pilih Semester Akademik" horizontal>
              <v-select v-model="form.semester_id" label="name" :options="semester" :reduce="x => x.id"></v-select>
            </b-field>
            <hr>
            <b-field label="Pilih Tahun Akademik" horizontal>
              <v-select v-model="form.tahun_akademik_id" label="name" :options="tahun_akademik" :reduce="x => x.id"></v-select>
            </b-field>
            <hr>
            <b-field label="Pilih Status" horizontal>
              <v-select v-model="form.status" label="name" :options="status" :reduce="x => x.id"></v-select>
            </b-field>
            <b-field horizontal>
              <b-button
                type="is-primary"
                :loading="isLoading"
                native-type="submit"
                >Submit</b-button
              >
            </b-field>
          </form>
        </card-component>
        <!-- <card-component
          v-if="isProfileExists"
          title="Profil Proposal"
          icon="account"
          class="tile is-child"
        >
          <b-field label="Pilih Jenjang">
            <b-input :value="form.jenjang" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Pilih Semester Akademik">
            <b-input :value="form.semester" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Pilih Tahun Akademik">
            <b-input :value="form.tahun_akademik" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Pilih Status">
            <b-input :value="form.Status" custom-class="is-static" readonly />
          <b-field label="Created">
            <b-input
              :value="form.created_at"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <hr />
        </card-component> -->

      </tiles>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'Form',
  components: {
    CardComponent,
    Tiles,
    HeroBar,
    TitleBar,
    vSelect
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      form: this.getClearFormObject(),
      createdReadable: null,
      isProfileExists: false,
      kbk: [],
      jenjang: [],
      kelas: [],
      semester: [],
      tahun_akademik: [],
      status: [
        {
          id: 0,
          name: 'Tidak Aktif'
        },
        {
          id: 1,
          name: 'Aktif'
        }
      ]
    }
  },
  computed: {
    titleStack () {
      let lastCrumb

      if (this.isProfileExists) {
        lastCrumb = 'Edit Proposal'
      } else {
        lastCrumb = 'Tambah Proposal'
      }

      return ['Dashboard', 'Setting Proposal', lastCrumb]
    },
    heroTitle () {
      if (this.isProfileExists) {
        return 'Edit Proposal'
      } else {
        return 'Tambah Proposal'
      }
    },
    heroRouterLinkTo () {
      if (this.isProfileExists) {
        return { name: 'proposal.create' }
      } else {
        return '/'
      }
    },
    heroRouterLinkLabel () {
      if (this.isProfileExists) {
        return 'Tambah Proposal'
      } else {
        return 'Dashboard'
      }
    },
    formCardTitle () {
      if (this.isProfileExists) {
        return 'Edit Proposal'
      } else {
        return 'Tambah Proposal'
      }
    }
  },
  watch: {
    id (newValue) {
      this.isProfileExists = false

      if (!newValue) {
        this.form = this.getClearFormObject()
      } else {
        this.getData()
      }
    }
  },
  mounted () {
    axios
      .get('/tahun_akademik')
      .then((r) => {
        this.tahun_akademik = r.data.data
      })
      .catch((e) => {
        this.isLoading = false
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger'
        })
      })
    axios
      .get('/semester')
      .then((r) => {
        this.semester = r.data.data
      })
      .catch((e) => {
        this.isLoading = false
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger'
        })
      })
    axios
      .get('/jenjang')
      .then((r) => {
        this.jenjang = r.data.data
      })
      .catch((e) => {
        this.isLoading = false
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger'
        })
      })
  },
  created () {
    this.getData()
  },
  methods: {
    getClearFormObject () {
      return {
        noid: null,
        name: null,
        email: null,
        phone: null
      }
    },
    getData () {
      if (this.$route.params.id) {
        const id = parseInt(this.$route.params.id)
        axios
          .get(`/akademik/${id}`)
          .then((r) => {
            console.log(r.data)
            if (r.data.status === 'ok') {
              this.form = r.data.data
              this.form.kbk_id = parseInt(this.form.kbk_id)
              this.isProfileExists = true
            }
          })
          .catch((e) => {
            this.$buefy.toast.open({
              message: `Error: ${e.message}`,
              type: 'is-danger',
              queue: false
            })
          })
      }
    },
    handleResponse (r) {
      console.log(r.data)
      this.isLoading = false

      this.$buefy.snackbar.open({
        message: (typeof r.data.message === 'object') ? `error: ${r.data.message.join(', ')}` : r.data.message,
        type: r.data.status === 'ok' ? 'is-primary' : 'is-danger',
        queue: false
      })
      if (r.data.status === 'ok') {
        this.$router.push({ name: 'setting_proposal' })
      }
    },
    handleError (e) {
      this.isLoading = false
      this.$buefy.toast.open({
        message: `Error: ${e.message}`,
        type: 'is-danger',
        queue: false
      })
    },
    submit () {
      const data = this.form
      data.password = data.noid

      this.isLoading = true

      if (this.$route.params.id) {
        axios
          .put(`/akademik/${this.$route.params.id}`, data)
          .then(this.handleResponse)
          .catch(this.handleError)
      } else {
        axios
          .post('/akademik', data)
          .then(this.handleResponse)
          .catch(this.handleError)
      }
    }
  }
}
</script>
